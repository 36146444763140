<template>
  <div class="col-md-4 col-12">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Património Total</h5>

        <div :class="{ 'text-center': isEmpty }" class="card-text">
          <div :key="account.id" v-for="account in sortedAccounts">
            {{ account.name }}: {{ account.balance }}
          </div>

          <div
            v-show="isEmpty"
            class="spinner-border text-secondary"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "TotalPatrimony",
  computed: {
    ...mapState(["AccountsNumber"]),
    ...mapGetters(["Accounts"]),
    isEmpty() {
      return this.AccountsNumber === 0;
    },
    sortedAccounts() {
      let accounts = Object.values(this.Accounts);
      accounts.sort((a, b) => {
        return b.balance - a.balance;
      });
      return accounts;
    },
  },
};
</script>