<template>
  <h1>Accounts</h1>
  <Loader v-show="isEmpty" />
  <div v-show="!isEmpty" :key="row.id" v-for="row in rawData">
    <p>{{ row.id }}: {{ row.name }} ({{ row.balance }} €)
      <pre>{{row}}</pre>
    </p>
  </div>
</template>



<script>
import Loader from "@/components/Loader";

export default {
  name: "RawAccounts",
  components: {
    Loader,
  },
  props: {
    rawData: Object,
  },
  computed: {
    isEmpty() {
      return Object.values(this.rawData).length === 0;
    },
  },
};
</script>