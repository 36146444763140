<template>
  <div class="container">
    <div class="row g-5">
      <div class="col-md-6 col-12">
        <RawAccounts :rawData="Accounts" />
      </div>

      <div class="col-md-6 col-12">
        <RawTransactions :rawData="Transactions" />
      </div>
    </div>
  </div>
</template>
<script>
import RawAccounts from "../components/RawData/RawAccounts.vue";
import RawTransactions from "../components/RawData/RawTransactions.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "RawDataView",
  components: { RawAccounts, RawTransactions },
  computed: {
    ...mapState(["Transactions"]),
    ...mapGetters(["Accounts"]),
  },
};
</script>
<style scoped>
.container {
  margin-top: 3em;
}
</style>