<template>
  <div class="col-md-4 col-12">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Distribuição por categoria</h5>
        <div class="card-text text-center" id="distribuicaoCategoriaChart">
          <pie-chart
            v-show="!isEmpty"
            :data="Dashboard.byCat"
            :colors="colors"
          ></pie-chart>
          <Loader v-show="isEmpty" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import { mapState } from "vuex";

export default {
  name: "ChartByCategory",
  components: {
    Loader,
  },
  computed: {
    ...mapState(["Dashboard"]),
    isEmpty() {
      return Object.values(this.Dashboard.byCat).length === 0;
    },
    colors() {
      return Object.keys(this.Dashboard.byCat).map((s) => s.toHex());
    },
  },
};
</script>