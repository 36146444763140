import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import DashboardView from '@/views/DashboardView.vue'
import RawDataView from '@/views/RawDataView.vue'
import { isTrue } from "@/utils/utils"

const routes = [
  {
    path: '/',
    name: "DashboardView",
    component: DashboardView
  },
  {
    path: '/rawdata',
    name: "Dados",
    component: RawDataView
  }
]


const myCreateWebHistory = isTrue(process.env.VUE_APP_HASH_HISTORY) ? createWebHashHistory : createWebHistory;
const router = createRouter({
  history: myCreateWebHistory(process.env.BASE_URL),
  routes
})

export default router
