import { createStore } from 'vuex'
import { trimLastChar } from "@/utils/utils"

const API_URL = trimLastChar(process.env.VUE_APP_API_URL || "./stub-api/", "/")

export default createStore({
  state() {
    return {
      API_URL: API_URL,
      Dashboard: {
        total: 0,
        byCat: {},
        byType: {},
      },
      AccountsNumber: 0,
      AccountsBackend: {},
      TransactionsNumber: 0,
      Transactions: {},
    }
  },
  getters: {
    Accounts: (state) => {
      if ("AccountsBackend" in state) {
        var clone = Object.assign({}, state.AccountsBackend);
        delete clone["-"];
        return clone;
      }
    }
  },
  mutations: {
    setAccountsMap(state, accounts) {
      state.AccountsBackend = {
        "-": { id: "-", name: "-" },
        ...accounts
      };
      state.AccountsNumber = Object.values(accounts).length;
    },
    storeTransaction(state, transaction) {
      if (transaction.id === undefined) {
        transaction.id = -state.TransactionsNumber
      }
      state.Transactions[transaction.id] = transaction;
      state.TransactionsNumber++;
    },
    setDashboardState(state, dashboard) {
      state.Dashboard = dashboard;
    },
    appendTransaction(state, transaction) {
      if (transaction.id === undefined) {
        transaction.id = -state.TransactionsNumber
      }
      state.Transactions[transaction.id] = transaction;
      state.TransactionsNumber++;

      if (transaction.account_id !== undefined && transaction.account_id !== "-") {
        const acc = state.AccountsBackend[transaction.account_id];
        acc.balance += transaction.value;

        state.Dashboard.byCat[acc.category] = (state.Dashboard.byCat[acc.category] || 0) + transaction.value;
        state.Dashboard.byType[acc.type] = (state.Dashboard.byType[acc.type] || 0) + transaction.value;
      }
    }
  },
  actions: {
    async fetchStateFromAPI(context) {

      let dashboard = {
        total: 0,
        byCat: {},
        byType: {},
      }

      const resp = await fetch(`${API_URL}/accounts`);
      const accounts = await resp.json();

      let accountsMap = {};
      accounts.forEach(account => {
        accountsMap[account.id] = { balance: 0, ...account };
      });


      // this part may be async
      const resptr = await fetch(`${API_URL}/transactions`);
      const transactions = await resptr.json();

      transactions.forEach(transaction => {
        if (transaction.account_id) {
          let acc = accountsMap[transaction.account_id]

          acc.balance += transaction.value;
          dashboard.byType[acc.type] = (dashboard.byType[acc.type] || 0) + transaction.value;
          dashboard.byCat[acc.category] = (dashboard.byCat[acc.category] || 0) + transaction.value;

          context.commit("storeTransaction", transaction);
        }
      });

      context.commit("setAccountsMap", accountsMap);
      context.commit("setDashboardState", dashboard);
    },
  },
})