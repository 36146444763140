<template>
  <div class="col-md-4 col-12">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Distribuição por tipo</h5>
        <div class="card-text text-center" id="distribuicaoTipoChart">
          <pie-chart
            v-show="!isEmpty"
            :data="Dashboard.byType"
            :colors="colors"
          ></pie-chart>
          <Loader v-show="isEmpty" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "ChartByType",
  components: {
    Loader,
  },
  computed: {
    ...mapState(["Dashboard"]),
    isEmpty() {
      return Object.values(this.Dashboard.byType).length === 0;
    },
    colors() {
      return Object.keys(this.Dashboard.byType).map((s) => s.toHex());
    },
  },
};
</script>