import { createApp } from 'vue'
import store from "./store"
import App from './App.vue'
import VueChartkick from 'vue-chartkick'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import 'chartkick/chart.js'
import './utils/utils'
import router from './router'

createApp(App)
  .use(router)
  .use(store)
  .use(VueChartkick)
  .mount('#app')
