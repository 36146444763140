<template>
  <!-- Modal -->
  <newTransactionModal />

  <!-- Button trigger modal -->
  <button type="button" class="btn btn-primary" @click="showModal">
    Adicionar Transação
  </button>
</template>

<script>
import { Modal } from "bootstrap";
import newTransactionModal from "@/components/newTransactionModal.vue";

export default {
  name: "InputTransactions",
  components: {
    newTransactionModal,
  },
  methods: {
    showModal() {
      const modal = Modal.getOrCreateInstance(
        document.getElementById("newTransactionModal")
      );
      modal.toggle();
    },
  },
};
</script>

<style scoped>
.action {
  padding: 10px;
}
</style>