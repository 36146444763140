<template>
  <Navbar />
  <router-view />
</template>

<script>
import Navbar from "./components/Navbar.vue";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: {
    Navbar,
  },
  methods: {
    ...mapActions(["fetchStateFromAPI"]),
  },
  created() {
    this.fetchStateFromAPI();
  },
};
</script>
