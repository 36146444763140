<template>
  <div class="col-md-4 col-12">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Totais por tipo</h5>

        <div :class="{ 'text-center': isEmpty }" class="card-text">
          <div :key="type.id" v-for="type in sortedValues">
            {{ type[0] }}: {{ type[1] }}
          </div>

          <div
            v-show="isEmpty"
            class="spinner-border text-secondary"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TotalByType",
  computed: {
    ...mapState(["Dashboard"]),
    isEmpty() {
      return Object.values(this.Dashboard.byType).length === 0;
    },
    sortedValues() {
      let byType = Object.entries(this.Dashboard.byType);
      byType.sort((a, b) => {
        return b[1] - a[1];
      });
      return byType;
    },
  },
};
</script>