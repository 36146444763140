<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto me-auto">
          <li class="nav-item active">
            <router-link class="nav-link" to="/">Dashboard </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/rawdata">Dados </router-link>
          </li>
        </ul>
      </div>
      <InputTransactionsButton />
    </div>
  </nav>
</template>

<script>
import InputTransactionsButton from "@/components/InputTransactionsButton.vue";

export default {
  name: "Navbar",
  components: {
    InputTransactionsButton,
  },
};
</script>