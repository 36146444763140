<template>
  <div class="col-md-4 col-12">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Evolução mensal</h5>
        <div class="card-text text-center" id="evolucaoMensalChart">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MensalGrowth",
};
</script>