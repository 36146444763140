<template>
  <div
    class="modal fade"
    id="newTransactionModal"
    tabindex="-1"
    aria-labelledby="newTransactionModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Nova Transação</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row g-3">
              <div class="form-floating col-md-6">
                <select
                  v-model="newTransaction.kind"
                  class="form-select"
                  id="transactionTypeSelection"
                  aria-label="Transaction type selection"
                >
                  <option
                    v-for="kind in transactionKinds"
                    :key="kind"
                    :value="kind"
                  >
                    {{ kind }}
                  </option>
                </select>
                <label for="transactionTypeSelection">Tipo</label>
              </div>

              <div class="form-floating col-md-6">
                <select
                  v-model="newTransaction.account"
                  class="form-select"
                  id="accountSelectionInput"
                  aria-label="Account selection"
                >
                  <option
                    v-for="account in AccountsBackend"
                    :key="account.id"
                    :value="account.id"
                  >
                    {{ account.name }}
                  </option>
                </select>
                <label for="accountSelectionInput">Conta</label>
              </div>

              <div class="form-floating col-md-6">
                <input
                  v-model.number="newTransaction.amount"
                  type="number"
                  class="form-control"
                  id="transactionAmountInput"
                  aria-label="Amount"
                  step="10"
                  autofocus
                />
                <label for="transactionAmountInput">Valor</label>
              </div>

              <div class="form-floating col-md-6">
                <input
                  v-model="newTransaction.date"
                  type="date"
                  class="form-control"
                  id="transactionDateInput"
                  aria-label="Date selection"
                />
                <label for="transactionDateInput">Data</label>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click.prevent="saveNewTransaction"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { mapMutations, mapState } from "vuex";

export default {
  name: "newTransactionModal",
  data() {
    return {
      isWithdraw: true,
      transactionKinds: ["Top-up", "Snapshot"],
      newTransaction: {
        kind: "Top-up",
        account: "-",
        amount: 100,
        date: new Date().toISOString().slice(0, 10),
      },
    };
  },
  computed: {
    isWithdrawText() {
      return this.isWithdraw ? "Depósito" : "Levantamento";
    },
    ...mapState(["AccountsBackend"]),
  },
  methods: {
    ...mapMutations(["appendTransaction"]),
    saveNewTransaction() {
      this.appendTransaction({
        type: this.newTransaction.kind,
        account_id: this.newTransaction.account,
        name: this.AccountsBackend[this.newTransaction.account].name,
        value: this.newTransaction.amount,
        date: this.newTransaction.date,
      });
      const modal = Modal.getInstance(
        document.getElementById("newTransactionModal")
      );
      modal.hide();
    },
  },
};
</script>
