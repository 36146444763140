<template>
  <h1>Transactions</h1>
  <Loader v-show="isEmpty" />
  <div v-show="!isEmpty" :key="element[1].id" v-for="element in orderdData">
    <p>{{ element[1].id }}: {{ element[1].type }} ({{ element[1].name }}) - {{ element[1].value }}€
      <pre>{{element[1]}}</pre>
    </p>
  </div>
</template>



<script>
import Loader from "@/components/Loader";

export default {
  name: "RawTransactions",
  components: {
    Loader,
  },
  props: {
    rawData: Object,
  },
  computed: {
    isEmpty() {
      return Object.values(this.rawData).length === 0;
    },
    orderdData() {
      let dataArray = Object.entries(this.rawData);
      if (dataArray !== undefined) {
        dataArray.sort(([, a], [, b]) => {
          b.id - a.id;
        });
      }
      return dataArray;
    },
  },
};
</script>