<template>
  <div class="container-xl p-4">
    <!-- Numbers only -->
    <div class="row g-5 mb-5">
      <TotalPatrimony />
      <TotalByType />
      <Variation />
    </div>

    <!-- Some Charts -->
    <div class="row g-5">
      <MensalGrowth />
      <ChartByCategory />
      <ChartByType />
    </div>
  </div>
</template>

<script>
import TotalPatrimony from "@/components/DashboardComponents/TotalPatrimony.vue";
import TotalByType from "@/components/DashboardComponents/TotalByType";
import Variation from "@/components/DashboardComponents/Variation";
import MensalGrowth from "@/components/DashboardComponents/MensalGrowth";
import ChartByCategory from "@/components/DashboardComponents/ChartByCategory";
import ChartByType from "@/components/DashboardComponents/ChartByType";

export default {
  name: "DashboardView",
  components: {
    TotalPatrimony,
    TotalByType,
    Variation,
    MensalGrowth,
    ChartByCategory,
    ChartByType,
  },
};
</script>
