String.prototype.toHex = function () {
  let hash = 0;
  if (this.length === 0) return hash;
  for (let i = 0; i < this.length; i++) {
    hash = this.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  var color = '#';
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 255;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

export function isTrue(value) {
  try {
    if (
      value === true ||
      value === 1 ||
      value === "1" ||
      value.toLowerCase() === "true" ||
      value.toLowerCase() === "on" ||
      value.toLowerCase() === "t"
    ) {
      return true
    }
  } catch {
    return false
  }
  return false
}

export function trimLastChar(str, char) {
  str = str.trim();
  if (str[str.length - 1] === char) {
    return str.substr(0, str.length - 1);
  }
  return str;
}